import { Route } from '@angular/router';

export const worksheetsRoutes: Route[] = [
  {
    path: 'list',
    loadComponent: () => import('./worksheets-list-page/worksheets-list-page.component').then( m => m.WorksheetsListPageComponent),
    data: {animation: '1'},
    children: [
      {
        path: 'create',
        loadComponent: () => import('./add-worksheet-modal/add-worksheet-modal.component').then( m => m.AddWorksheetModalComponent)
      },
    ]
  },
  {
    path: ':worksheetId',
    loadComponent: () => import('./worksheet-page/worksheet-page.component').then( m => m.WorksheetPageComponent),
    data: {animation: '3'},
  },
  {
    path: '**',
    redirectTo: 'list',
    pathMatch: 'full'
  }
];
