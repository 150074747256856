import { TranslocoService } from '@ngneat/transloco';
import { inject, Injectable } from '@angular/core';
import { CanMatchFn, Route, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AuthService } from './auth.service';
import { first, lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {

  constructor(
    private router: Router,
    private authService: AuthService,
    private messageService: MessageService,
    private translocoService: TranslocoService
  ) {}

  isLoggedIn() {

    // If no token
    if (localStorage.getItem('token') === null) {
      return this.router.createUrlTree(['/auth']);
    } else {
      // If token exists
      return true;
    }

  }

  isNotLoggedIn() {
    // If no token
    if (localStorage.getItem('token') === null) {
      return true;
    } else {
      // If token exists
      return this.router.createUrlTree(['/']);
    }
  }

  async hasRoles(route: Route) {

    // If route has roles and user is logged in
    if (route.data && route.data['roles'] && this.authService.token) {

      // If user data is not loaded yet
      if (!this.authService.userData) {

        // Wait for user data to load
        await lastValueFrom(this.authService.userChanges$.pipe(first()));

      }

      // If user role is not in requested roles
      if (!route.data['roles'].includes(this.authService.userData?.role)) {

        // Show error message
        this.messageService.add({severity:'error', detail: this.translocoService.translate('errorMessages.' + 'forbidden'), key: 'main', life: 5000})

        return this.router.createUrlTree(['/settings']);

      }

      return true;

    } else {

      return this.router.createUrlTree(['/settings']);

    }

  }

  async hasModule(route: Route) {

    // If route has module and user is logged in
    if (route.data && route.data['module'] && this.authService.token) {

      // If user data is not loaded yet
      if (!this.authService.userData) {

        // Wait for user data to load
        await lastValueFrom(this.authService.userChanges$.pipe(first()));

      }

      const requestedModule = route.data['module'];

      // Check if user's garage has the requested module
      const garageHasModule = this.authService.userData?.garage?.modules.find((m) => m.module === requestedModule);

      // If user's garage does not have the requested module enabled
      if (!garageHasModule) {

        // Show error message
        this.messageService.add({severity:'error', detail: this.translocoService.translate('errorMessages.' + 'forbidden'), key: 'main', life: 5000})

        return this.router.createUrlTree(['/settings']);

      }

      return true;

    } else {

      return this.router.createUrlTree(['/settings']);

    }

  }

  async hasScope(route: Route) {

    // If route has module and user is logged in
    if (route.data && route.data['module'] && route.data['scope'] && this.authService.token) {

      // If user data is not loaded yet
      if (!this.authService.userData) {

        // Wait for user data to load
        await lastValueFrom(this.authService.userChanges$.pipe(first()));

      }

      const requestedModule = route.data['module'];
      const requestedScope = route.data['scope'];
      const redirectTo = route.data['redirectTo'];

      // Check if user's garage has the requested module
      const garageHasModule = this.authService.userData?.garage?.modules.find((m) => m.module === requestedModule);

      // If user's garage does not have the requested module enabled
      if (!garageHasModule) {

        // Show error message
        this.messageService.add({severity:'error', detail: this.translocoService.translate('errorMessages.' + 'forbidden'), key: 'main', life: 5000})

        return redirectTo ? this.router.createUrlTree(redirectTo) : this.router.createUrlTree(['/settings']);

      } else {

        // Check if user's garage has the requested scope
        const garageHasScope = garageHasModule.scopes.find((s) => s.scope === requestedScope);

        // If user's garage does not have the requested scope enabled
        if (!garageHasScope) {

          // If redirect to is set, don't show error message (this is for tyre storage different scopes)
          if (redirectTo) {

            return this.router.createUrlTree(redirectTo);

          } else {

            // Show error message
            this.messageService.add({severity:'error', detail: this.translocoService.translate('errorMessages.' + 'forbidden'), key: 'main', life: 5000})
            return this.router.createUrlTree(['/settings']);

          }


        }

      }

      return true;

    } else {

      return this.router.createUrlTree(['/settings']);

    }

  }


}

export const isNotLoggedIn: CanMatchFn = () => {
  return inject(AuthGuard).isNotLoggedIn();
};

export const isLoggedIn: CanMatchFn = () => {
  return inject(AuthGuard).isLoggedIn();
};

export const hasRoles: CanMatchFn = (route) => {
  return inject(AuthGuard).hasRoles(route);
};

export const hasModule: CanMatchFn = (route) => {
  return inject(AuthGuard).hasModule(route);
};

export const hasScope: CanMatchFn = (route) => {
  return inject(AuthGuard).hasScope(route);
};
