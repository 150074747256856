import { Routes } from "@angular/router";
import { hasRoles } from "src/app/auth/auth.guard";

export const settingsRoutes: Routes = [
  {
    path: 'profile',
    loadComponent: () => import('./profile-tab/profile-tab.component').then(m => m.ProfileTabComponent),
    title: 'Profile settings | MGM',
    data: { animation: '1'},
  },
  {
    path: 'garage',
    loadComponent: () => import('./garage-tab/garage-tab.component').then(m => m.GarageTabComponent),
    canMatch: [hasRoles],
    data: {roles: ['ADMIN'], animation: '2'},
    title: 'Garage settings | MGM',
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'profile',
  }
];
