import {
  trigger,
  transition,
  style,
  query,
  group,
  animate,
} from '@angular/animations';

export const slider =
  trigger('routeAnimations', [
    transition('* => isLeft', slideTo('left') ),
    transition('* => isRight', slideTo('right') ),
    transition('isRight => *', slideTo('left') ),
    transition('isLeft => *', slideTo('right') )
  ]);


function slideTo(direction: string) {
  return [
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        [direction]: 0,
        width: '100%',
        height: '100%'
      })
    ], { optional: true }),
    query(':enter', [
      style({ [direction]: '-100%'})
    ], { optional: true }),
    group([
      query(':leave', [
        animate('500ms ease', style({ [direction]: '100%'}))
      ], { optional: true }),
      query(':enter', [
        animate('500ms ease', style({ [direction]: '0%'}))
      ], { optional: true })
    ]),
  ];
}

export const fader =
  trigger('routeAnimations', [
    transition('* <=> *', [
      query(':leave', [
        style({
          opacity: 1,
        }),
      ], { optional: true }),
      query(':enter', [
        style({
          opacity: 0,
          display: 'none',
          position: 'absolute',
          top: 0,
          left: 0,
      })
      ], { optional: true }),
      query(':leave', [
        animate('300ms ease', style({ opacity: 0 })),
        style({ position: 'absolute' })
      ], { optional: true }),
      query(':enter', [
        style({ display: 'block', position: 'relative' }),
        animate('300ms ease', style({ opacity: 1 }))
      ], { optional: true }),
    ]),
  ]);

