import { Route } from '@angular/router';
import { hasModule } from '../../auth/auth.guard';

export const accountabilityRoutes: Route[] = [
  {
    path: 'incoming-invoices',
    loadComponent: () => import('./in-invoices-list-tab/in-invoices-list-tab.component').then( m => m.InInvoicesListTabComponent),
    data: {animation: '1'}
  },
  {
    path: 'outgoing-invoices',
    canMatch: [hasModule],
    data: {module: 'ACCOUNTABILITY', animation: '2'},
    loadComponent: () => import('./out-invoices-list-tab/out-invoices-list-tab.component').then( m => m.OutInvoicesListTabComponent),
  },
  {
    path: '',
    redirectTo: 'incoming-invoices',
    pathMatch: 'full'
  }
]
