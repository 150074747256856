import { Routes } from "@angular/router";
import { hasRoles, hasScope } from "src/app/auth/auth.guard";

export const inventoryRoutes: Routes = [
  {
    path: 'tyres',
    loadComponent: () => import('./tyres-inventory-tab/tyres-inventory-tab.component').then(m => m.TyresInventoryTabComponent),
    title: 'Tyres inventory | MGM',
    data: { animation: '1'},
  },
  {
    path: 'spare-parts',
    loadComponent: () => import('./spare-parts-inventory-tab/spare-parts-inventory-tab.component').then(m => m.SparePartsInventoryTabComponent),
    data: {animation: '2'},
    title: 'Spare parts inventory | MGM',
  },
  {
    path: 'garages/:connectionId',
    loadComponent: () => import('./tyrestorage-dispatcher-tab/tyrestorage-dispatcher-tab.component').then(m => m.TyrestorageDispatcherTabComponent),
    data: {animation: '4'},
    title: 'Garage stored tyres | Tyre storage | MGM',
    children: [
      {
        path: 'send-order',
        loadComponent: () => import('./tyrestorage-dispatcher-tab/send-storage-order-modal/send-storage-order-modal.component').then(m => m.SendStorageOrderModalComponent),
      }
    ]
  },
  {
    path: 'tyrestorage',
    loadComponent: () => import('./tyrestorage-stocker-tab/tyrestorage-stocker-tab.component').then(m => m.TyrestorageStockerTabComponent),
    canMatch: [hasScope],
    data: {animation: '3', module: 'INVENTORY', scope: 'TYRESTORAGE', },
    title: 'Tyre storage | MGM',
    children: [
      {
        path: 'customers-tyres',
        loadComponent: () => import('./tyrestorage-stocker-tab/customers-tyres-list-tab/customers-tyres-list-tab.component').then(m => m.CustomersTyresListTabComponent),
        data: {animation: '6'},
        title: 'Customers\' tyres list | Tyre storage | MGM',
        children: [
          {
            path: 'store',
            loadComponent: () => import('./tyrestorage-stocker-tab/customers-tyres-list-tab/store-customers-tyres-modal/store-customers-tyres-modal.component').then(m => m.StoreCustomersTyresModalComponent),
            data: {animation: '7'},
            title: 'Store customers tyres | Tyre storage | MGM',
          }
        ]
      },
      {
        path: 'garages/list',
        loadComponent: () => import('./tyrestorage-stocker-tab/garages-list-tab/garages-list-tab.component').then(m => m.GaragesListTabComponent),
        data: {animation: '9'},
        title: 'Connected garages list | Tyre storage | MGM',
        children: [
          {
            path: 'add',
            loadComponent: () => import('./tyrestorage-stocker-tab/add-tyrestorage-garage-modal/add-tyrestorage-garage-modal.component').then(m => m.AddTyrestorageGarageModalComponent),
            canMatch: [hasRoles],
            data: {animation: '5', roles: ['ADMIN'],},
            title: 'Add garage | Tyre storage | MGM',
          },
        ]
      },
      {
        path: 'garages/:connectionId',
        loadComponent: () => import('./tyrestorage-stocker-tab/dispatcher-tab/dispatcher-tab.component').then(m => m.DispatcherTabComponent),
        data: {animation: '8'},
        title: 'Garage stored tyres | Tyre storage | MGM',
      },
      {
        path: '**',
        pathMatch: 'full',
        redirectTo: 'customers-tyres',
      }
    ]
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'tyres',
  }
];
