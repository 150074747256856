import { Route } from '@angular/router';
import { hasScope } from '../../auth/auth.guard';

export const vehiclesRoutes: Route[] = [
  {
    path: 'list',
    loadComponent: () => import('./vehicles-list-page/vehicles-list-page.component').then( m => m.VehiclesListPageComponent),
    data: {animation: '1'},
    children: [
      {
        path: 'create',
        canMatch: [hasScope],
        data: {module: 'VEHICLES', scope: 'CREATE', animation: '2'},
        loadComponent: () => import('./add-vehicle-modal/add-vehicle-modal.component').then( m => m.AddVehicleModalComponent)
      },
    ]
  },
  {
    path: ':vehicleId',
    loadComponent: () => import('./vehicle-page/vehicle-page.component').then( m => m.VehiclePageComponent),
    data: {animation: '3'},
    children: [
      {
        path: 'info',
        data: {animation: '4'},
        loadComponent: () => import('./vehicle-page/vehicle-info-tab/vehicle-info-tab.component').then( m => m.VehicleInfoTabComponent),
      },
      {
        path: 'tyres',
        data: {animation: '5'},
        loadComponent: () => import('./vehicle-page/vehicle-tyres-tab/vehicle-tyres-tab.component').then( m => m.TyresTabComponent),
        children: [
          {
            path: 'add',
            loadComponent: () => import('./vehicle-page/vehicle-tyres-tab/add-tyres-modal/add-tyres-modal.component').then( m => m.AddTyresModalComponent),
            data: {animation: '6'}
          },
        ]
      },
      {
        path: 'spare-parts',
        data: {animation: '7'},
        loadComponent: () => import('./vehicle-page/vehicle-spare-parts-tab/vehicle-spare-parts-tab.component').then( m => m.VehicleSparePartsTabComponent),
      },
      {
        path: '**',
        redirectTo: 'info',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'list',
    pathMatch: 'full'
  }
];
