import { TranslocoService } from '@ngneat/transloco';
import { User } from '../shared/interfaces/common.interface';
import { Router } from '@angular/router';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Subject, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  token: string | undefined;
  userData: User | undefined | null;

  userChanges$ = new Subject<User>();

  constructor(
    private http: HttpClient,
    private router: Router,
    private translocoService: TranslocoService,
  ) {
    // Load token
    const t = localStorage.getItem('token');

    if (t) {

      this.setAuthToken(t);

      // Get user from backend to be sure it's up to date and to get garage modules
      this.getAuthenticatedUser();

    }
  }

  logIn(username: string, password: string) {

    // Return an promise instead of an observable to avoid the need of subscribing and unsubscribing
    return lastValueFrom(this.http.post(environment.backendUrl + '/auth/logIn', {
      username,
      password,
    }).pipe(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      tap((response: any) => {
        // If the login is successful, save the token in the local storage
        localStorage.setItem('token', response.token);

        this.setAuthToken(response.token);

        this.getAuthenticatedUser();

      })
    ));

  }

  /**
   * Log out
   */
  logOut() {

    this.router.navigate(['/auth']);

    return this.removeAuthToken();
  }


  /**
   * Set auth token into local storage
   *
   * @param token auth token to be set
   */
  setAuthToken(token: string) {
    localStorage.setItem('token', token);
    this.token = token;
  }

  /**
   * Remove auth token from local storage
   */
  removeAuthToken() {
    localStorage.removeItem('token');
  }

  /**
   * Get auth token
   */
  getAuthToken(): string | null {
    return localStorage.getItem('token');
  }

  getAuthenticatedUser() {

    return lastValueFrom(this.http.get<User>(environment.backendUrl + '/auth/user').pipe(
      tap((user) => {
        this.userData = user;
        this.userChanges$.next(user);
      }
    ))).catch((err) => {
      this.logOut(); // Log out on error
      throw err;
    });

  }

  updateAuthenticatedUser(user: User) {

    return lastValueFrom(this.http.patch<User>(environment.backendUrl + '/auth/user', user).pipe(
      tap((user) => {
        this.userData = {...user, garage: this.userData!.garage};
        this.userChanges$.next(user);
      }
    )));

  }

  updateAuthenticatedUserPassword(password: string) {

    return lastValueFrom(this.http.patch(environment.backendUrl + '/auth/user/password', {password}));

  }

  setAuthenticatedUserPicture(picture: Blob | null) {

    const formData = new FormData();

    if (picture) {
      formData.append('picture', picture);
    }

    return lastValueFrom(this.http.post(environment.backendUrl + '/auth/picture', formData)).then(() => {

      if (this.userData) {
        this.userData.hasPicture = true;
        this.userChanges$.next(this.userData);
      }

    });

  }

  removeAuthenticatedUserPicture() {

    const formData = new FormData();

    formData.append('picture', '');

    return lastValueFrom(this.http.post(environment.backendUrl + '/auth/picture', formData)).then(() => {

      if (this.userData) {
        this.userData.hasPicture = false;
        this.userChanges$.next(this.userData);
      }

    });

  }

  // ! --------------- SIGN UP -----------------

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  signUpTyreStorageGarage(data: any, inviteCode: string) {

    return lastValueFrom(this.http.post<{token: string}>(environment.backendUrl + '/auth/tyre-storage/signUp/' + inviteCode, data));

  }

  checkTyreStorageInviteCode(code: string) {

    return lastValueFrom(this.http.get(environment.backendUrl + '/auth/tyre-storage/invite/' + code));

  }

  searchCompanyInfoByVat(vat: string) {

    return lastValueFrom(this.http.get(environment.backendUrl + '/companies/search', {params: {vat, lang: this.translocoService.getActiveLang()}}));

  }

  // ! ------------ UTILS ---------------

  userHasModule(module: string) {

    if (!this.userData) {
      return false;
    }

    return this.userData.garage.modules.findIndex(m => m.module === module) !== -1;

  }

  userHasScope(module: string, scope: string) {

    if (!this.userData) {
      return false;
    }

    const moduleIndex = this.userData.garage.modules.findIndex(m => m.module === module);

    if (moduleIndex === -1) {
      return false;
    }

    return this.userData.garage.modules[moduleIndex].scopes.findIndex(s => s.scope === scope) !== -1;

  }


}
