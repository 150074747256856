import { Injectable, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {
  TRANSLOCO_CONFIG,
  TRANSLOCO_FALLBACK_STRATEGY,
  TRANSLOCO_LOADER,
  Translation,
  TranslocoFallbackStrategy,
  TranslocoLoader,
  TranslocoModule,
  getBrowserLang,
  translocoConfig,
  TRANSLOCO_SCOPE,
} from '@ngneat/transloco';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { TranslocoPersistLangModule, TRANSLOCO_PERSIST_LANG_STORAGE } from '@ngneat/transloco-persist-lang';
import { AvatarUrlPipe } from './shared/pipes/avatar-url.pipe';

// Fallback languages for each language
export const FALLBACK_TRANSLATION_MODEL: { [key: string]: string[] } = {
  default: ['en', 'fr', 'it'], // Default fallbacks to English and French
  en: ['fr', 'it'], // English fallbacks to French
  fr: ['en', 'it'], // French fallbacks to English
  it: ['en', 'fr'], // Italian fallbacks to English and French
};

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string) {
    return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
  }
}

// Custom fallback strategy
export class CustomStrategy implements TranslocoFallbackStrategy {
  getNextLangs() {

    const browserLang = getBrowserLang();

    // Get fallback languages for the browser language
    const fallbackLangs =
      (browserLang && FALLBACK_TRANSLATION_MODEL[browserLang]) ||
      FALLBACK_TRANSLATION_MODEL['default'];

    return fallbackLangs;
  }
}

@NgModule({
  imports: [
    TranslocoLocaleModule.forRoot(
      {
        langToLocaleMapping: {
          en: 'en-US',
          fr: 'fr-FR',
          it: 'it-IT',
        }
      }
    ),
    TranslocoPersistLangModule.forRoot({
      storage: {
        provide: TRANSLOCO_PERSIST_LANG_STORAGE,
        useValue: localStorage,
      },
    }),
  ],
  exports: [
    TranslocoModule,
    HttpClientModule,
    TranslocoLocaleModule
  ],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: ['en', 'fr', 'it'],
        defaultLang: 'en',
        fallbackLang: 'en',
        missingHandler: {
          useFallbackTranslation: true,
        },
        reRenderOnLangChange: true,
      }),
    },
    {
      provide: TRANSLOCO_SCOPE,
      useValue: '' // Leave this empty to set no scope
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
    { provide: TRANSLOCO_FALLBACK_STRATEGY, useClass: CustomStrategy },
  ],
})
export class TranslocoRootModule {}
