import { Route } from '@angular/router';
import { hasModule, hasScope } from '../../auth/auth.guard';

export const customersRoutes: Route[] = [
  {
    path: 'list',
    loadComponent: () => import('./customers-list-page/customers-list-page.component').then( m => m.CustomersListPageComponent),
    data: {animation: '1'},
    children: [
      {
        path: 'create',
        canMatch: [hasScope],
        data: { module: 'CUSTOMERS', scope: 'CREATE', animation: '2'},
        loadComponent: () => import('./add-customer-modal/add-customer-modal.component').then( m => m.AddCustomerModalComponent)
      },
    ]
  },
  {
    path: ':customerId',
    loadComponent: () => import('./customer-page/customer-page.component').then( m => m.CustomerPageComponent),
    data: {animation: '3'},
    children: [
      {
        path: 'info',
        data: {animation: '4'},
        loadComponent: () => import('./customer-page/customer-info-tab/customer-info-tab.component').then( m => m.CustomerInfoTabComponent),
      },
      {
        path: 'vehicles',
        canMatch: [hasModule],
        data: {module: 'VEHICLES', animation: '5'},
        loadComponent: () => import('./customer-page/customer-vehicles-tab/customer-vehicles-tab.component').then( m => m.CustomerVehiclesTabComponent),
      },
      {
        path: '**',
        redirectTo: 'info',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'list',
    pathMatch: 'full'
  }
];
