/* eslint-disable @typescript-eslint/no-explicit-any */
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, delay, map } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class InterceptorService implements HttpInterceptor {

  constructor(
    private router: Router,
    private messageService: MessageService,
    private translocoService: TranslocoService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('token');

    if (token) {
      // If we have a token, we set it to the header

      request = request.clone({
        headers: request.headers.set('authorization', `Bearer ${token}`),
      });
    }

    return next.handle(request).pipe(
      //delay(1000), // ! REMOVE THIS ON PRODUCTION
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      map((event: HttpEvent<any>) => {

        // If response is a request
        if (event instanceof HttpResponse) {

          // Show message
          if (event.body.message) {
            this.messageService.add({severity:'success', detail: this.translocoService.translate('successMessages.' + event.body.message, event.body.messageParams), key: 'main'})
          }

          return event.clone({body: event.body.data});
        }

        return event
      }),
      catchError((err) => {

        // If Unauthorized
        if (err.status === 401) {
          this.router.navigate(['/auth']);

          localStorage.removeItem('token');
        }

        // If Forbidden redirect to settings
        if (err.status === 403) {
          this.router.navigate(['/settings']);
        }

        // Clear all messages before showing new ones
        this.messageService.clear('main');

        // Handle errors using toast
        for (const msg of err.error.errorMessages) {

          this.messageService.add({severity:'error', detail: this.translocoService.translate('errorMessages.' + msg.msg, msg?.msgParams), key: 'main', life: 5000})
        }

        return throwError(() => err.error);
      })
    );
  }

}
