import { Routes } from "@angular/router";
import { isNotLoggedIn, isLoggedIn } from "./auth/auth.guard";
import { authRoutes } from "./auth/auth.routes";
import { pagesRoutes } from "./pages/pages.routes";
import { settingsRoutes } from "./pages/settings/settings.routes";

export const appRoutes: Routes = [
  {
    path: 'auth',
    canMatch: [isNotLoggedIn],
    data: { animation: 'isLeft'},
    children: authRoutes
  },
  {
    path: '',
    loadComponent: () => import('./pages/pages.component').then(m => m.PagesComponent),
    canMatch: [isLoggedIn],
    children: pagesRoutes,
    data: { animation: 'isRight'},
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'auth',
  },
];
