import { Route } from '@angular/router';
import { hasRoles, hasScope } from '../../auth/auth.guard';

export const employeesRoutes: Route[] = [
  {
    path: 'list',
    loadComponent: () => import('./employees-list-page/employees-list-page.component').then( m => m.EmployeesListPageComponent),
    data: {animation: '1'},
    title: 'Employees list | MGM',
    children: [
      {
        path: 'create',
        canMatch: [hasRoles, hasScope],
        data: {roles: ['ADMIN'], module: 'EMPLOYEES', scope: 'CREATE', animation: '2'},
        loadComponent: () => import('./add-employee-modal/add-employee-modal.component').then( m => m.AddEmployeeModalComponent)
      },
    ]
  },
  {
    path: ':employeeId',
    loadComponent: () => import('./employee-page/employee-page.component').then( m => m.EmployeePageComponent),
    data: {animation: '3'},
    children: [
      {
        path: 'info',
        data: {animation: '4'},
        loadComponent: () => import('./employee-page/employee-info-tab/employee-info-tab.component').then( m => m.EmployeeInfoTabComponent),
      },
      {
        path: '**',
        redirectTo: 'info',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'list',
    pathMatch: 'full'
  }
];
