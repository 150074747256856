import { TranslocoRootModule } from './../../../transloco-root.module';
import { FormsModule } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { Component, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';

@Component({
  selector: 'ui-lang-picker',
  standalone: true,
  imports: [CommonModule, DropdownModule, TranslocoRootModule, FormsModule],
  template: `

    <p-dropdown appendTo="body"  [options]="translocoService.getAvailableLangs()" [ngModel]="activeLang" (onChange)="translocoService.setActiveLang($event.value)">
      <ng-template let-lang pTemplate="selectedItem">
      <div style="width: 100px" class="flex align-items-center gap-1">
          <img src="assets/imgs/flag_placeholder.png" style="width: 17px;" [class]="'flag flag-' + (lang.toLowerCase() === 'en' ? 'gb' : lang.toLowerCase())" />
          <div>{{ 'langs.' + lang | transloco }}</div>
      </div>
      </ng-template>
      <ng-template let-lang pTemplate="item">
        <div style="width: 100px" class="flex align-items-center gap-1">
          <img src="assets/imgs/flag_placeholder.png" style="width: 17px;" [class]="'flag flag-' + (lang.toLowerCase() === 'en' ? 'gb' : lang.toLowerCase())" />
          <div>{{ 'langs.' + lang | transloco }}</div>
        </div>
      </ng-template>
    </p-dropdown>
  `,
  styles: [
  ]
})
export class LangPickerComponent implements OnDestroy {

  activeLang = 'en';

  sub;

  constructor(
    public translocoService: TranslocoService,
  ) {

    this.sub = translocoService.langChanges$.subscribe((lang) => {
      this.activeLang = lang;
    })


  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }


}
