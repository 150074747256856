import { worksheetsRoutes } from './worksheets/worksheets.routes';
import { accountabilityRoutes } from './accountability/accountability.routes';
import { inventoryRoutes } from './inventory/inventory.routes';
import { shopRoutes } from './shop/shop.routes';
import { vehiclesRoutes } from './vehicles/vehicles.routes';
import { Routes } from "@angular/router";
import { hasRoles, hasModule, isLoggedIn, hasScope } from "../auth/auth.guard";
import { customersRoutes } from "./customers/customers.routes";
import { employeesRoutes } from "./employees/employees.routes";
import { settingsRoutes } from "./settings/settings.routes";
import { ordersRoutes } from './orders/orders.routes';

export const pagesRoutes: Routes = [
  {
    path: 'dashboard',
    data: {animation: '1'},
    title: 'Dashboard | MGM',
    loadComponent: () => import('./dashboard/dashboard.component').then( m => m.DashboardComponent),
  },
  {
    path: 'employees',
    loadComponent: () => import('./employees/employees.component').then( m => m.EmployeesComponent),
    canMatch: [hasRoles, hasModule],
    data: {roles: ['ADMIN', 'MANAGER', 'SECRETARY'], module: 'EMPLOYEES', animation: '2'},
    title: 'Employees | MGM',
    children: employeesRoutes
  },
  {
    path: 'customers',
    loadComponent: () => import('./customers/customers.component').then( m => m.CustomersComponent),
    canMatch: [hasModule],
    data: {module: 'CUSTOMERS', animation: '4'},
    title: 'Customers | MGM',
    children: customersRoutes
  },
  {
    path: 'vehicles',
    loadComponent: () => import('./vehicles/vehicles.component').then( m => m.VehiclesComponent),
    canMatch: [hasModule],
    data: {module: 'VEHICLES', animation: '5'},
    title: 'Vehicles | MGM',
    children: vehiclesRoutes
  },
  {
    path: 'worksheets',
    loadComponent: () => import('./worksheets/worksheets.component').then( m => m.WorksheetsComponent),
    title: 'Worksheets | MGM',
    children: worksheetsRoutes
  },
  {
    path: 'inventory/garages/:connectionId/orders/:orderId',
    loadComponent: () => import('./inventory/tyrestorage-dispatcher-tab/tyrestorage-order-page/tyrestorage-order-page.component').then(m => m.TyrestorageOrderPageComponent),
    canMatch: [hasModule],
    data: {animation: '12', module: 'INVENTORY'},
    title: 'Garage stored tyres | Tyre storage | MGM',
  },
  {
    path: 'inventory/tyrestorage/garages/:connectionId/orders/:orderId',
    loadComponent: () => import('./inventory/tyrestorage-stocker-tab/dispatcher-tab/tyre-storage-order-page/tyre-storage-order-page.component').then(m => m.TyreStorageOrderPageComponent),
    canMatch: [hasScope],
    data: {animation: '10', module: 'INVENTORY', scope: 'TYRESTORAGE'},
    title: 'Garage stored tyres | Tyre storage | MGM',
  },
  {
    path: 'inventory',
    loadComponent: () => import('./inventory/inventory.component').then( m => m.InventoryComponent),
    canMatch: [hasModule],
    data: {module: 'INVENTORY', animation: '9'},
    title: 'Inventory | MGM',
    children: inventoryRoutes
  },
  {
    path: 'shop',
    loadComponent: () => import('./shop/shop.component').then( m => m.ShopComponent),
    data: { animation: '6'},
    title: 'Shop | MGM',
    children: shopRoutes
  },
  {
    path: 'checkout',
    loadComponent: () => import('./checkout/checkout.component').then( m => m.CheckoutComponent),
    data: { animation: '7'},
    title: 'Checkout | MGM',
  },
  {
    path: 'orders',
    loadComponent: () => import('./orders/orders.component').then( m => m.OrdersComponent),
    data: { animation: '8'},
    title: 'Orders | MGM',
    children: ordersRoutes
  },
  {
    path: 'accountability',
    loadComponent: () => import('./accountability/accountability.component').then( m => m.AccountabilityComponent),
    canMatch: [hasRoles],
    data: { animation: '11', roles: ['ADMIN', 'MANAGER', 'SECRETARY']},
    title: 'Accountability | MGM',
    children: accountabilityRoutes,
  },
  {
    path: 'settings',
    loadComponent: () => import('./settings/settings.component').then( m => m.SettingsComponent),
    canMatch: [isLoggedIn],
    data: { animation: '3'},
    title: 'Settings | MGM',
    children: settingsRoutes
  },
  {
    path: '**',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
];
