import { Routes } from "@angular/router";

export const shopRoutes: Routes = [
  {
    path: 'tyres',
    loadComponent: () => import('./tyres-shop-tab/tyres-shop-tab.component').then(m => m.TyresShopTabComponent),
    title: 'Tyres shop | MGM',
    data: { animation: '1'},
  },
  {
    path: 'spare-parts',
    loadComponent: () => import('./spare-parts-shop-tab/spare-parts-shop-tab.component').then(m => m.SparePartsShopTabComponent),
    data: {animation: '2'},
    title: 'Spare parts shop | MGM',
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'tyres',
  }
];
