import { environment } from './environments/environment';
import { TranslocoDatePipe } from '@ngneat/transloco-locale';
import { ConfirmationService, MessageService } from 'primeng/api';
import { InterceptorService } from './app/interceptor.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  provideRouter,
  withEnabledBlockingInitialNavigation,
} from '@angular/router';
import { AppComponent } from './app/app.component';
import { appRoutes } from './app/app.routes';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { TranslocoPipe, TranslocoService } from '@ngneat/transloco';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslocoRootModule } from './app/transloco-root.module';
import { CurrencyPipe } from '@angular/common';

bootstrapApplication(AppComponent, {
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    MessageService,
    TranslocoPipe,
    ConfirmationService,
    TranslocoService,
    TranslocoDatePipe,
    CurrencyPipe,
    importProvidersFrom(
      HttpClientModule,
      TranslocoRootModule,
      TranslocoPipe,
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: environment.isPwa,
        registrationStrategy: 'registerWhenStable:30000'
      }),
    ),
    provideRouter(appRoutes, withEnabledBlockingInitialNavigation()),
    provideAnimations(),
  ],
}).catch((err) => console.error(err));
