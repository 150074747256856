import { Route } from '@angular/router';

export const ordersRoutes: Route[] = [
  {
    path: 'list',
    loadComponent: () => import('./orders-list-page/orders-list-page.component').then( m => m.OrdersListPageComponent),
    data: {animation: '1'},
    title: 'Orders list | MGM',
  },
  {
    path: ':orderId',
    loadComponent: () => import('./order-page/order-page.component').then( m => m.OrderPageComponent),
    data: {animation: '3'},
  },
  {
    path: '**',
    redirectTo: 'list',
    pathMatch: 'full'
  }
];
